import { useCallback, useSyncExternalStore } from "react";

/**
 * Hook to listen for matches to a specified CSS media query
 */
export const useMediaQuery = (query: string) => {
    const subscribe = useCallback(
        (callback: EventListenerOrEventListenerObject) => {
            const matchMedia = window.matchMedia(query);

            matchMedia.addEventListener("change", callback);

            return () => {
                matchMedia.removeEventListener("change", callback);
            };
        },
        [query],
    );

    // https://react.dev/reference/react/useSyncExternalStore#subscribing-to-a-browser-api
    return useSyncExternalStore(subscribe, () => window.matchMedia(query).matches);
};
